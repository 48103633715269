var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{staticClass:"base-container-x pt-4",staticStyle:{"min-height":"500px","background-color":"#fff"}},[_c('h2',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.$t('Change password')))]),_c('b-row',[_c('b-col',{staticClass:"mt-5 px-0",attrs:{"lg":"6","sm":"12","offset-lg":"3"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":_vm.$t('Old password'),"rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark",attrs:{"for":"input-oldpassword","label":_vm.$t('Old password')+'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-oldpassword","type":"password","placeholder":_vm.$t('Old password')},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('New password'),"rules":"required|min:8|verify_password|confirmed:form.password2","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark",attrs:{"for":"input-newpassword1","label":_vm.$t('New password')+'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-newpassword1","type":"password","placeholder":_vm.$t('New password')},model:{value:(_vm.form.new_password1),callback:function ($$v) {_vm.$set(_vm.form, "new_password1", $$v)},expression:"form.new_password1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Confirm new password'),"autocomplete":"off","rules":"required|min:8|verify_password","vid":"form.password2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark",attrs:{"for":"input-newpassword2","label":_vm.$t('Confirm new password') +'*'}},[_c('b-form-input',{staticClass:"input-auth",attrs:{"id":"input-newpassword2","type":"password","placeholder":_vm.$t('Confirm new password')},model:{value:(_vm.form.new_password2),callback:function ($$v) {_vm.$set(_vm.form, "new_password2", $$v)},expression:"form.new_password2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"row"},[_c('b-col',{staticClass:"d-flex justify-content-center",attrs:{"sm":"12","lg":"12"}},[_c('b-button',{staticClass:"mt-2  px-5 my-4 btn-light font-weight-bold d-inline",attrs:{"pill":"","type":"reset"},on:{"click":function($event){return _vm.reset()}}},[_vm._v(_vm._s(_vm.$t('Cancel')))]),_c('b-button',{staticClass:"d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark",attrs:{"pill":"","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{staticClass:"mr-3",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(_vm._s(_vm.$t('Save')))],1)],1)],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }