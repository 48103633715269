<template>
  <div>
    <b-container class="base-container-x pt-4" style="min-height:500px;background-color:#fff">
      <h2 class="text-center">{{ $t('Change password') }}</h2>
      <b-row>
        <b-col lg="6" sm="12" offset-lg="3" class="mt-5 px-0">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
              <validation-provider
                  :name="$t('Old password')"
                  rules="required"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark" for="input-oldpassword" :label="$t('Old password')+'*'">
                  <b-form-input id="input-oldpassword" type="password" class="input-auth" v-model="form.old_password" :placeholder="$t('Old password')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  :name="$t('New password')"
                  rules="required|min:8|verify_password|confirmed:form.password2"
                  autocomplete="off"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark" for="input-newpassword1" :label="$t('New password')+'*'">
                  <b-form-input id="input-newpassword1" type="password" class="input-auth" v-model="form.new_password1" :placeholder="$t('New password')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                  :name="$t('Confirm new password')"
                  autocomplete="off"
                  rules="required|min:8|verify_password"
                  vid="form.password2"
                  v-slot="{errors}"
                >
                <b-form-group class="d-inline  col-sm-12 col-lg-12 font-weight-bold text-primary-dark" for="input-newpassword2" :label="$t('Confirm new password') +'*'">
                  <b-form-input id="input-newpassword2" type="password" class="input-auth" v-model="form.new_password2" :placeholder="$t('Confirm new password')"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <div class="row">
                <b-col sm="12" lg="12" class="d-flex justify-content-center">
                  <b-button pill type="reset" @click="reset()" class="mt-2  px-5 my-4 btn-light font-weight-bold d-inline">{{ $t('Cancel') }}</b-button>
                  <b-button pill type="submit" :disabled="isLoading" class="d-inline ml-3 mt-2 px-5 my-4 font-weight-bold btn-primary-dark"><b-spinner small class="mr-3" v-if="isLoading"  label="Loading..."/>{{ $t('Save') }}</b-button>
                </b-col>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Api from '../api/api'

export default {
  name: 'ChangePassword',
  title: ' | Changepassword',
  data () {
    return {
      isLoading: false,
      form: {
        old_password: '',
        new_password1: '',
        new_password2: ''
      }
    }
  },
  async mounted () {
    this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    },
    onSubmit () {
      this.$refs.form.validate().then(result => {
        if (result) {
          this.isLoading = true
          this.textError = false
          Api.changePassword(this.form)
            .then((response) => {
              this.isLoading = false
              this.$notify({
                group: 'success',
                text: this.$t('Change password successfully')
              })
              this.reset()
            })
            .catch((error) => {
              this.isLoading = false
              let txtError = ''
              if (error.response.data) {
                for (const data in error.response.data) {
                  if (data === 'old_password') {
                    txtError = this.$t('Old password invalid')
                  } else {
                    txtError += error.response.data[data].join('<br>') + '<br>'
                  }
                }
                this.textError = txtError
              } else {
                this.textError = this.$t('Change password unsuccessfully')
              }
              this.$notify({
                group: 'error',
                text: txtError
              })
            })
        }
      })
    },
    reset () {
      this.$nextTick(() => {
        this.form.old_password = ''
        this.form.new_password1 = ''
        this.form.new_password2 = ''
        this.$refs.form.reset()
      })
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>
